import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'enumArrayToString',
    standalone: false
})
export class EnumArrayConvertedToStringPipe implements PipeTransform {
  transform(enumsArray: Array<any>, enumObject: any): any {
    let val: string = '';
    if (enumsArray && typeof enumsArray !== 'undefined') {
      enumsArray.forEach((e, i) => {
        if (enumObject) {
          val += enumObject[e];
          if (i + 1 < enumsArray.length) {
            val += ', ';
          }
        }
      });
    }
    return val;
  }
}
