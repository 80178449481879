import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {from, mergeMap, Observable} from 'rxjs';
import {AUTH_SERVICE_TOKEN, AuthService} from '../service/auth.service';
import {environment} from "../../../environments/environment";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  public constructor(@Inject(AUTH_SERVICE_TOKEN) private readonly authService: AuthService) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const isApiUrl = req.url.startsWith(environment.apiEndpoint);

    if (!isApiUrl) {
      return next.handle(req);
    }

    return from(this.authService.getToken())
      .pipe(mergeMap(token => {
        const authorizedReq = req.clone({headers: req.headers.set('Authorization', `Bearer ${token}`)});
        return next.handle(authorizedReq);
      }));
  }
}
