import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, EventEmitter, Inject, Input, isDevMode, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {
  SidebarCloseAnimation,
  SidebarOpenAnimation,
} from 'src/app/shared/animations/mobile-user-sidebar-toggle-animation';

import { AUTH_SERVICE_TOKEN, AuthService, AdvisorsService } from 'src/app/shared/service/';

import { Advisor } from '../../shared/model';

import { Groups } from '../../shared/enum/groups.enum';
import { Roles } from '../../shared/enum/roles.enum';

const animationParams = {
  menuWidth: '250px',
  animationStyle: '500ms ease',
};

@Component({
    selector: 'app-user-sidebar',
    templateUrl: './user-sidebar.component.html',
    styleUrls: ['./user-sidebar.component.scss'],
    animations: [
        trigger('sideMenu', [
            transition(':enter', [
                useAnimation(SidebarOpenAnimation, {
                    params: {
                        ...animationParams,
                    },
                }),
            ]),
            transition(':leave', [
                useAnimation(SidebarCloseAnimation, {
                    params: {
                        ...animationParams,
                    },
                }),
            ]),
        ]),
    ],
    standalone: false
})
export class UserSidebarComponent implements OnInit {
  @Input() isOpen: boolean;

  @Output() close = new EventEmitter();

  protected readonly isDevMode = isDevMode;

  protected devMode: boolean = false;
  protected highestPriorityGroup: Groups | null = null;

  protected advisor: Advisor;
  protected pin: string = '';

  constructor(
    @Inject(AUTH_SERVICE_TOKEN) protected authService: AuthService,
    protected readonly advisorsService: AdvisorsService,
    private readonly toasterService: ToastrService,
    private readonly router: Router,
  ) {}

  public ngOnInit(): void {
    this.getServices();

    if (isDevMode()) {
      this.devMode = true;
    }
  }

  private getServices(): void {
    this.advisorsService.getAdvisorProfile().subscribe({
      next: (advisor: Advisor): void => {
        this.advisor = advisor;

        if (this.advisor?.pin) {
          this.pin = this.advisor?.pin;
        }
      },
      error: (error: any): void => {},
    });
  }

  protected navigateTo(route: string): void {
    this.closeFlyoutMenu();
    this.router.navigate([route]);
  }

  protected closeFlyoutMenu(): void {
    this.close.emit(false);
  }

  protected goToOtherExternalUrl(url: string): void {
    window.open(url, '_blank');
    this.closeFlyoutMenu();
  }

  protected navigateToPool(): void {
    if (this.authService.hasRole(Roles.POOL_CONNECTION)) {
      this.router.navigate(['/pools']);
    } else {
      this.router.navigate(['/profile/restricted-product/pool-connections']);
    }

    this.closeFlyoutMenu();
  }

  protected copyPin(): void {
    navigator.clipboard.writeText(this.advisor.pin);
    this.callToastrMsg('PIN in die Zwischenablage kopiert');
  }

  protected logout(): void {
    this.authService.logout();
  }

  private callToastrMsg(msg: string): void {
    this.toasterService.info(msg);
  }

  protected readonly Groups = Groups;
  protected readonly Roles = Roles;
}
