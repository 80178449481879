import { Person } from '../auth/person';
import { TerminationDetails } from './termination-details';
import { ContractGroup } from './contract-group';
import { Category } from '../category';
import { Attachment } from '../attachment.model';
import { ITodo } from '../todo.interface';
import { InsuranceGroup, InsuranceProvider } from '../insurance.model';
import { ContractAdvice } from './contract-advice';
import { PaymentIntervalTypeEnum } from '../../enum';

export class Contract {
  id: number;
  uid: string;
  title: string;
  created: Date;
  contractStartDate: Date | string;
  contractEndDate: Date | string;
  owner: Person;
  contractType: string;
  insuranceGroup: InsuranceGroup = null;
  insuranceProvider: InsuranceProvider = null;

  insuranceGroupName?: string;

  spriteCoordinate: string;
  insuranceProviderName?: string;
  contractNumber: string;
  clientNumber: string;
  paymentIntervalType: string;
  contractGroup: ContractGroup = null;
  amount: number;
  note: string;
  sharable: boolean;
  category: Category;
  terminationDetails: TerminationDetails = new TerminationDetails();
  attachments: Array<Attachment> = new Array<Attachment>();
  unreadMessages: number;
  totalMessages: number;
  closedTodos: Array<ITodo> = new Array<ITodo>();
  openTodos: Array<ITodo> = new Array<ITodo>();
  rating: number;
  advice: string;
  lastUpdate: Date;
  contractAdvice: ContractAdvice;
  icon: string;
  isPoolContract: boolean;
  poolStatus: Map<string, string>;
  processStatus: string;
  contractPoolTransferState: string;
  poolContractLink: string;
  poolIdentification: string;
  editMode: boolean;
  advisorCanEdit: boolean;
  activeConversation: boolean;
  finoContractId: string;
  archived: boolean;
}

export interface IPill {
  title: string;
  active: boolean;
  uploadParam: string;
}

export class ContractOverviewRequest {
  criteria: ContractOverviewCriteria[] = [];
  sort: string[] = [];
  perPage: number = 25;
  pageNr: number = 0;
}

export class ContractOverviewResponse {
  totalPages: number;
  totalNrOfItems: number;
  pageNr: number;
  data: ContractShortModel[];
}

export class ContractOverviewCriteria {
  field: string;
  operation: 'EQUALITY' | 'LIKE' | 'IS_NULL' | 'IS_NOT_NULL';
  value: string[];
}

export class ContractShortModel {
  uuid: string;
  clientFirstName: string;
  clientLastName: string;
  clientId: number;
  advisorFirstName: string;
  advisorLastName: string;
  insuranceGroupName: string;
  insuranceProviderName: string;
  amount: number;
  intervalType: string;
  contractNumber: string;
  poolIdentification: null;
  contractPoolTransferState: string;
  spriteCoordinate: string;
}

export class DetectedContractsResponseModel {
  insurance: Contract[];
  other: Contract[];
}
