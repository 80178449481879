import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'lastEight',
    standalone: false
})
export class LastEightPipe implements PipeTransform {
  transform(value: string): string {
    if (value) {
      return value ? '...' + value.slice(-8) : value;
    } else {
      return '-';
    }
  }
}
