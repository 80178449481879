import { Directive, Input, Renderer2, ElementRef, OnInit, OnDestroy } from '@angular/core';

@Directive({
    selector: '[appDelaySkeleton]',
    standalone: false
})
export class DelaySkeletonDirective implements OnInit, OnDestroy {
  @Input() appDelaySkeleton: number = 400;

  private timeoutId: any;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {}

  public ngOnInit(): void {
    // Initially hide the skeleton
    this.renderer.setStyle(this.el.nativeElement, 'display', 'none');

    // Show the skeleton after the specified delay
    this.timeoutId = setTimeout((): void => {
      this.renderer.setStyle(this.el.nativeElement, 'display', 'block');
    }, this.appDelaySkeleton);
  }

  public ngOnDestroy(): void {
    // Clear timeout to avoid memory leaks
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }
}
