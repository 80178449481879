@if (isFondsFinanz) {
  <div class="pool-synch-modal-container">
    <div class="button-wrapper" style="height: 42px">
      <button
        type="button"
        id="page-header-new-mandates"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="true"
        class="btn btn-lg btn-circle btn-no-bg btn-noborder d-flex align-items-center justify-content-center mr-10 position-relative"
        style="height: 42px; width: 42px"
        title="Poolimporter Status"
        [disabled]="status === 'STARTED'"
        (click)="poolAction()"
      >
        @if (status === '' || status === null || status === 'INTERRUPTED' || status === 'FINISHED') {
          <i class="bi bi-arrow-repeat text-20"></i>
        }
        @if (status === 'STARTED') {
          <i class="fa fa-spinner fa-spin"></i>
        } @else if (status === 'INTERRUPTED') {
          <i
            class="bi bi-exclamation-triangle-fill fa-1x position-absolute text-danger"
            style="font-size: 10px; top: 1px; right: 1px"
          ></i>
        } @else if (status === 'FINISHED') {
          <i
            class="bi bi-check-lg fa-1x position-absolute text-success"
            style="font-size: 11px; top: 6px; right: 1px"
          ></i>
        }
      </button>
    </div>
  </div>
}
