import { Directive, Inject, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { AUTH_SERVICE_TOKEN, AuthService } from '../service';

// Use directive for show/hide content
// *appHasGroup="[Groups.COMPANY_ADMIN, Groups.TRAINEE, Groups.ADVISOR, Groups.BACKOFFICE]"

// For change styles e t c
// u need to call this.authService.isInGroup(group)  or this.authService.hasAllNeededGroup(this.groups) (for multiple).;
// use these variables for detect groups: isTrainee isCompanyAdmin isAdvisor isBackoffice

@Directive({
    selector: '[appHasGroup]',
    standalone: false
})
export class GroupCheckDirective {
  private groups: string[];

  constructor(
    @Inject(AUTH_SERVICE_TOKEN) private readonly authService: AuthService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {}

  @Input()
  set appHasGroup(groups: string[]) {
    this.groups = groups;
    this.updateView();
  }

  private updateView(): void {
    if (this.authService.hasAllNeededGroup(this.groups)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
