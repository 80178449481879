<div class="client-invitation-modal-container">
  <div class="modal-body p-0">
    <div class="row">
      <div class="col-12 col-md-4 col-lg-3 img-background pl-20 pt-20">
        <img ngSrc="https://static-assets.vinlivt.de/logo/icon/splash-b2b-40x40.svg" width="40" height="40" alt="v" />
      </div>

      <div class="col-12 col-md-8 col-lg-9 layout-padding">
        <div class="row">
          <div class="col-12 mb-15">
            <div class="section-title">Kunden einladen</div>
          </div>

          <!--          <div class="col-12 mt-15">-->
          <!--            <div class="form-group">-->
          <!--              <label>Mit Link einladen</label>-->
          <!--              @if (loading) {-->
          <!--                <ngx-skeleton-loader-->
          <!--                  appearance="line"-->
          <!--                  [theme]="{-->
          <!--                    width: '100%',-->
          <!--                    height: '32px',-->
          <!--                    margin: '0'-->
          <!--                  }"-->
          <!--                ></ngx-skeleton-loader>-->
          <!--              } @else {-->
          <!--                <div class="input-group">-->
          <!--                  <input disabled type="text" class="form-control" [value]="link" />-->
          <!--                  <div class="input-group-append">-->
          <!--                    <span class="input-group-text">-->
          <!--                      <img-->
          <!--                        src="https://static-assets.vinlivt.de/icons/line-icons/svg/clipboard.svg"-->
          <!--                        (click)="copyToClipBoard(link, true)"-->
          <!--                        class="cursor-pointer"-->
          <!--                        alt="clip icon"-->
          <!--                      />-->
          <!--                    </span>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              }-->
          <!--            </div>-->
          <!--          </div>-->

          <div class="col-12 mb-3">
            <div class="row">
              <div class="col-12">
                {{ advisor?.firstname + ' ' + advisor?.lastname }} lädt dich zur Vinlivt-App ein, kostenlos registrieren
                & direkt starten 🚀
              </div>

              <div class="col-12 mb-3">So geht’s:</div>

              <div class="col-12 d-flex flex-column mb-3">
                <div>1️⃣ App herunterladen</div>
                <div>2️⃣ Registrieren & E-Mail bestätigen</div>
                <div>3️⃣ Berater-Code eingeben:</div>
                <div class="mt-3">{{ advisor?.pin }}</div>
                <div class="mt-3">Zum App Download</div>

                <div class="mt-3">
                  📱
                  <a href="https://apps.apple.com/de/app/vinlivt/id6445957364" target="_blank">Apple Registrierung</a> |
                  📱
                  <a href="https://play.google.com/store/apps/details?id=de.vinlivt.b2c&hl=de" target="_blank"
                    >Google Registrierung
                  </a>
                </div>
                <div>Geschafft! Die App kann ab sofort genutzt werden. 🎉</div>
              </div>

              <div class="col-12">
                <button class="btn btn-block btn-white-outline btn-rounded" (click)="copyInvitationToClipBoard()">
                  <span class="mr-2">Einladung kopieren</span>
                  <i class="bi bi-copy"></i>
                </button>
              </div>
            </div>
          </div>

          <!--          <div class="col-12 mb-15">-->
          <!--            <div class="text-secondary">-->
          <!--              Bitte gib die E-Mail von deinem Freund:in ein und anschließend wird eine Einladung in deinem Namen-->
          <!--              versendet.-->
          <!--            </div>-->
          <!--          </div>-->

          <div class="col-12 mb-30">
            <div class="row">
              <div class="col-12">
                <label for="emails">Mit E-Mail einladen</label>

                <textarea
                  class="form-control form-control-lg"
                  [(ngModel)]="emails"
                  (input)="checkEmail($event)"
                  id="emails"
                  name="emails"
                  rows="4"
                  placeholder="max.muster@gmx.de, ..."
                ></textarea>
              </div>

              @if (duplicatedEmails?.length) {
                <div class="col-12 mt-15 d-flex flex-column">
                  <div class="text-12">Wurde bereits eingeladen:</div>

                  @for (email of duplicatedEmails; track email) {
                    <div class="text-danger text-12">{{ email }}</div>
                  }
                </div>
              }
            </div>
          </div>

          <div class="col-12">
            <div class="row">
              <div class="col-5">
                <button class="btn px-0" (click)="modalRef.hide()">Abbrechen</button>
              </div>

              <div class="col-7 text-right">
                <button
                  class="btn btn-rounded btn-action-secondary"
                  [disabled]="!emails || !isEmailValid"
                  [ngClass]="{ 'btn-action-disabled': !isEmailValid }"
                  (click)="inviteClients()"
                  id="invite-now-btn"
                >
                  <i class="fa fa-send mr-1"></i>
                  <span>Jetzt einladen</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
